export default [
  {
    path: '/calendario',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
    meta: {
      resource: 'Calendar',
      action: 'read',
    },
  },

  {
    path: '/calendario-reception',
    name: 'apps-calendarreception',
    component: () => import('@/views/apps/calendarreception/Calendar.vue'),
    meta: {
      resource: 'CalendarReception',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/compiti',
    name: 'apps-todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/compiti/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/compiti/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/comunicazioni',
    name: 'apps-conversations',
    component: () => import('@/views/apps/conversations/Conversations.vue'),
    meta: {
      resource: 'Conversations',
      action: 'read',
    },
  },
  {
    path: '/comunicazioni-reception',
    name: 'apps-conversationsreception',
    component: () => import('@/views/apps/conversationsreception/Conversations.vue'),
    meta: {
      resource: 'ConversationsReception',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/e-commerce/shop',
    name: 'apps-e-commerce-shop',
    component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Shop',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/wishlist',
    name: 'apps-e-commerce-wishlist',
    component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
    meta: {
      pageTitle: 'Wishlist',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Wishlist',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/checkout',
    name: 'apps-e-commerce-checkout',
    component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/:slug',
    name: 'apps-e-commerce-product-details',
    component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
    meta: {
      pageTitle: 'Product Details',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
        {
          text: 'Product Details',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/utenti',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      resource: 'Users',
      action: 'read',
    },
  },
  {
    path: '/utenti/visualizza/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
    meta: {
      resource: 'Users',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- CONTACTS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/contatti',
    name: 'apps-contacts-list',
    component: () => import('@/views/apps/contacts/contacts-list/ContactsList.vue'),
    meta: {
      resource: 'Contacts',
      action: 'read',
    },
  },
  {
    path: '/contatti/visualizza/:id',
    name: 'apps-contacts-view',
    component: () => import('@/views/apps/contacts/contacts-view/ContactsView.vue'),
    meta: {
      resource: 'Contacts',
      action: 'read',
    },
  },
  {
    path: '/contatti/modifica/:id',
    name: 'apps-contacts-edit',
    component: () => import('@/views/apps/contacts/contacts-edit/ContactsEdit.vue'),
    meta: {
      resource: 'Contacts',
      action: 'read',
    },
  },
  {
    path: '/contatti/aggiungi',
    name: 'apps-contacts-add',
    component: () => import('@/views/apps/contacts/contacts-add/ContactsAdd.vue'),
    meta: {
      resource: 'Contacts',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- DEPARTMENTS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/reparti',
    name: 'apps-departments-list',
    component: () => import('@/views/apps/departments/departments-list/DepartmentsList.vue'),
    meta: {
      resource: 'Departments',
      action: 'read',
    },
  },
  {
    path: '/reparti/visualizza/:id',
    name: 'apps-departments-view',
    component: () => import('@/views/apps/departments/departments-view/DepartmentsView.vue'),
    meta: {
      resource: 'Departments',
      action: 'read',
    },
  },
  {
    path: '/reparti/modifica/:id',
    name: 'apps-departments-edit',
    component: () => import('@/views/apps/departments/departments-edit/DepartmentsEdit.vue'),
    meta: {
      resource: 'Departments',
      action: 'read',
    },
  },
  {
    path: '/reparti/aggiungi',
    name: 'apps-departments-add',
    component: () => import('@/views/apps/departments/departments-add/DepartmentsAdd.vue'),
    meta: {
      resource: 'Departments',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- HEADQUARTERS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/sedi',
    name: 'apps-headquarters-list',
    component: () => import('@/views/apps/headquarters/headquarters-list/HeadquartersList.vue'),
    meta: {
      resource: 'Headquarters',
      action: 'read',
    },
  },
  {
    path: '/sedi/visualizza/:id',
    name: 'apps-headquarters-view',
    component: () => import('@/views/apps/headquarters/headquarters-view/HeadquartersView.vue'),
    meta: {
      resource: 'Headquarters',
      action: 'read',
    },
  },
  {
    path: '/sedi/modifica/:id',
    name: 'apps-headquarters-edit',
    component: () => import('@/views/apps/headquarters/headquarters-edit/HeadquartersEdit.vue'),
    meta: {
      resource: 'Headquarters',
      action: 'read',
    },
  },
  {
    path: '/sedi/aggiungi',
    name: 'apps-headquarters-add',
    component: () => import('@/views/apps/headquarters/headquarters-add/HeadquartersAdd.vue'),
    meta: {
      resource: 'Headquarters',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- MEETINGROOMS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/sale-riunioni',
    name: 'apps-meetingrooms-list',
    component: () => import('@/views/apps/meetingrooms/meetingrooms-list/MeetingroomsList.vue'),
    meta: {
      resource: 'Meetingrooms',
      action: 'read',
    },
  },
  {
    path: '/sale-riunioni/visualizza/:id',
    name: 'apps-meetingrooms-view',
    component: () => import('@/views/apps/meetingrooms/meetingrooms-view/MeetingroomsView.vue'),
    meta: {
      resource: 'Meetingrooms',
      action: 'read',
    },
  },
  {
    path: '/sale-riunioni/modifica/:id',
    name: 'apps-meetingrooms-edit',
    component: () => import('@/views/apps/meetingrooms/meetingrooms-edit/MeetingroomsEdit.vue'),
    meta: {
      resource: 'Meetingrooms',
      action: 'read',
    },
  },
  {
    path: '/sale-riunioni/aggiungi',
    name: 'apps-meetingrooms-add',
    component: () => import('@/views/apps/meetingrooms/meetingrooms-add/MeetingroomsAdd.vue'),
    meta: {
      resource: 'Meetingrooms',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- MEETINGROOMSRECEPTION ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/sale-riunioni-reception',
    name: 'apps-meetingroomsreception-list',
    component: () => import('@/views/apps/meetingroomsreception/meetingrooms-list/MeetingroomsList.vue'),
    meta: {
      resource: 'MeetingroomsReception',
      action: 'read',
    },
  },
  {
    path: '/sale-riunioni-reception/visualizza/:id',
    name: 'apps-meetingroomsreception-view',
    component: () => import('@/views/apps/meetingroomsreception/meetingrooms-view/MeetingroomsView.vue'),
    meta: {
      resource: 'MeetingroomsReception',
      action: 'read',
    },
  },
  {
    path: '/sale-riunioni-reception/modifica/:id',
    name: 'apps-meetingroomsreception-edit',
    component: () => import('@/views/apps/meetingroomsreception/meetingrooms-edit/MeetingroomsEdit.vue'),
    meta: {
      resource: 'MeetingroomsReception',
      action: 'read',
    },
  },
  {
    path: '/sale-riunioni-reception/aggiungi',
    name: 'apps-meetingroomsreception-add',
    component: () => import('@/views/apps/meetingroomsreception/meetingrooms-add/MeetingroomsAdd.vue'),
    meta: {
      resource: 'MeetingroomsReception',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- VEHICLES ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/veicoli',
    name: 'apps-vehicles-list',
    component: () => import('@/views/apps/vehicles/vehicles-list/VehiclesList.vue'),
    meta: {
      resource: 'Vehicles',
      action: 'read',
    },
  },
  {
    path: '/veicoli/visualizza/:id',
    name: 'apps-vehicles-view',
    component: () => import('@/views/apps/vehicles/vehicles-view/VehiclesView.vue'),
    meta: {
      resource: 'Vehicles',
      action: 'read',
    },
  },
  {
    path: '/veicoli/modifica/:id',
    name: 'apps-vehicles-edit',
    component: () => import('@/views/apps/vehicles/vehicles-edit/VehiclesEdit.vue'),
    meta: {
      resource: 'Vehicles',
      action: 'read',
    },
  },
  {
    path: '/veicoli/aggiungi',
    name: 'apps-vehicles-add',
    component: () => import('@/views/apps/vehicles/vehicles-add/VehiclesAdd.vue'),
    meta: {
      resource: 'Vehicles',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- VEHICLESRECEPTION ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/veicoli-reception',
    name: 'apps-vehiclesreception-list',
    component: () => import('@/views/apps/vehiclesreception/vehicles-list/VehiclesList.vue'),
    meta: {
      resource: 'VehiclesReception',
      action: 'read',
    },
  },
  {
    path: '/veicoli-reception/visualizza/:id',
    name: 'apps-vehiclesreception-view',
    component: () => import('@/views/apps/vehiclesreception/vehicles-view/VehiclesView.vue'),
    meta: {
      resource: 'VehiclesReception',
      action: 'read',
    },
  },
  {
    path: '/veicoli-reception/modifica/:id',
    name: 'apps-vehiclesreception-edit',
    component: () => import('@/views/apps/vehiclesreception/vehicles-edit/VehiclesEdit.vue'),
    meta: {
      resource: 'VehiclesReception',
      action: 'read',
    },
  },
  {
    path: '/veicoli-reception/aggiungi',
    name: 'apps-vehiclesreception-add',
    component: () => import('@/views/apps/vehiclesreception/vehicles-add/VehiclesAdd.vue'),
    meta: {
      resource: 'VehiclesReception',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- JUSTIFICATION PROPOSALS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/proposte-ferie',
    name: 'apps-justificationproposals-list',
    component: () => import('@/views/apps/justificationproposals/justificationproposals-list/JustificationproposalsList.vue'),
    meta: {
      resource: 'Justificationproposals',
      action: 'read',
    },
  },
  {
    path: '/proposte-ferie/visualizza/:id',
    name: 'apps-justificationproposals-view',
    component: () => import('@/views/apps/justificationproposals/justificationproposals-view/JustificationproposalsView.vue'),
    meta: {
      resource: 'Justificationproposals',
      action: 'read',
    },
  },
  {
    path: '/proposte-ferie/aggiungi',
    name: 'apps-justificationproposals-add',
    component: () => import('@/views/apps/justificationproposals/justificationproposals-add/JustificationproposalsAdd.vue'),
    meta: {
      resource: 'Justificationproposals',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- MANAGER JUSTIFICATION PROPOSALS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/proposte-ferie-utenti',
    name: 'apps-managerjustificationproposals-list',
    component: () => import('@/views/apps/managerjustificationproposals/justificationproposals-list/JustificationproposalsList.vue'),
    meta: {
      resource: 'ManagerJustificationproposals',
      action: 'read',
    },
  },
  {
    path: '/proposte-ferie-utenti/visualizza/:id',
    name: 'apps-managerjustificationproposals-view',
    component: () => import('@/views/apps/managerjustificationproposals/justificationproposals-view/JustificationproposalsView.vue'),
    meta: {
      resource: 'ManagerJustificationproposals',
      action: 'read',
    },
  },
  {
    path: '/proposte-ferie-utenti/aggiungi',
    name: 'apps-managerjustificationproposals-add',
    component: () => import('@/views/apps/managerjustificationproposals/justificationproposals-add/JustificationproposalsAdd.vue'),
    meta: {
      resource: 'ManagerJustificationproposals',
      action: 'read',
    },
  },

  // // *===============================================---*
  // // *--------- HOLIDAY PROPOSALS ---- ---------------------------------------*
  // // *===============================================---*
  // {
  //   path: '/proposte-ferie',
  //   name: 'apps-holidayproposals-list',
  //   component: () => import('@/views/apps/holidayproposals/holidayproposals-list/HolidayproposalsList.vue'),
  //   meta: {
  //     resource: 'Holidayproposals',
  //     action: 'read',
  //   },
  // },
  // {
  //   path: '/proposte-ferie/visualizza/:id',
  //   name: 'apps-holidayproposals-view',
  //   component: () => import('@/views/apps/holidayproposals/holidayproposals-view/HolidayproposalsView.vue'),
  //   meta: {
  //     resource: 'Holidayproposals',
  //     action: 'read',
  //   },
  // },
  // {
  //   path: '/proposte-ferie/modifica/:id',
  //   name: 'apps-holidayproposals-edit',
  //   component: () => import('@/views/apps/holidayproposals/holidayproposals-edit/HolidayproposalsEdit.vue'),
  //   meta: {
  //     resource: 'Holidayproposals',
  //     action: 'read',
  //   },
  // },
  // {
  //   path: '/proposte-ferie/aggiungi',
  //   name: 'apps-holidayproposals-add',
  //   component: () => import('@/views/apps/holidayproposals/holidayproposals-add/HolidayproposalsAdd.vue'),
  //   meta: {
  //     resource: 'Holidayproposals',
  //     action: 'read',
  //   },
  // },

  // Invoice
  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
  },

  // *===============================================---*
  // *--------- TIMBRATURE  ---------------------------------------*
  // *===============================================---*
  {
    path: '/timbrature',
    name: 'apps-worktimes-list',
    component: () => import('@/views/apps/worktimes/worktimes-list/WorktimesList.vue'),
    meta: {
      resource: 'Worktimes',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- JUSTIFICATIONS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/richieste-ferie',
    name: 'apps-justifications-list',
    component: () => import('@/views/apps/justifications/justifications-list/JustificationsList.vue'),
    meta: {
      resource: 'Justifications',
      action: 'read',
    },
  },
  {
    path: '/richieste-ferie/tipi',
    name: 'apps-justifications-types',
    component: () => import('@/views/apps/justifications/justifications-types/JustificationsTypes.vue'),
    meta: {
      resource: 'Justifications',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- EXPENSE NOTES ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/note-spesa',
    name: 'apps-expensenotes-list',
    component: () => import('@/views/apps/expensenotes/expensenotes-list/ExpensenotesList.vue'),
    meta: {
      resource: 'Expensenotes',
      action: 'read',
    },
  },
  {
    path: '/note-spesa/tipi',
    name: 'apps-expensenotes-types',
    component: () => import('@/views/apps/expensenotes/expensenotes-types/ExpensenotesTypes.vue'),
    meta: {
      resource: 'Expensenotes',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- CALENDAR USERS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/calendari-utente',
    name: 'apps-calendarusers-list',
    component: () => import('@/views/apps/calendarusers/calendarusers-list/CalendarusersList.vue'),
    meta: {
      resource: 'Calendarusers',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- MANAGER JUSTIFICATIONS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/richieste-ferie-utenti/:approvedId?',
    name: 'apps-managerjustifications-list',
    component: () => import('@/views/apps/managerjustifications/managerjustifications-list/ManagerjustificationsList.vue'),
    meta: {
      resource: 'ManagerJustifications',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- MANAGER JUSTIFICATIONS SCHEDULE ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/prospetto-ferie',
    name: 'apps-managerjustificationsschedule-list',
    component: () => import('@/views/apps/managerjustificationsschedule/managerjustificationsschedule-list/ManagerjustificationsscheduleList.vue'),
    meta: {
      resource: 'ManagerJustificationsSchedule',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- MANAGER CALENDAR ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/calendario-ferie',
    name: 'apps-managercalendar',
    component: () => import('@/views/apps/managercalendar/ManagerCalendar.vue'),
    meta: {
      resource: 'ManagerCalendar',
      action: 'read',
    },
  },
]
